import React from 'react'
import ProgressBar from './ProgressBar';

const Header = () => {
  return (
    <>
      <ProgressBar/>
    </>
  )
}

export default Header