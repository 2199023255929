import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
// import NavBar from './Components/property-listing/Navbar';
// import Form from './Components/AddNewProperty/Form';
// import LeftSideBar from './Components/property-listing/LeftSideBar';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);